<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>REQUERIMIENTO</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'FECHA DE RADICACIÓN'" :descripcion="form_qr.fecha_requerimiento"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'MACRO MOTIVO'" :obj="registro.gesdato.macromotivo"></texto3>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE REQUERIMIENTO'" :descripcion="registro.tipo.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'INSTANCIA DE RECEPCIÓN'" :descripcion="registro.instancia.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showCanal">
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_qr.canal_id" :rules="[rules.requerido]" :items="items.puntorecepcion"
              :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario"
              label="CANAL/PUNTO DE RECEPCIÓN *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.gesduplicado != null">
          <v-col cols="12" md="6"
            v-if="registro.fechacerrado != null && marcacionID(registro.gesduplicado) !== 2">
            <texto2 :colores="colores" :titulo="'MARCACIÓN'"
              :descripcion="registro.gesduplicado.marcacion.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="1" v-if="marcacionID(registro.gesduplicado) === 3">
            <v-btn @click="borrarMarcacion = true" icon :color="colores.red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1" v-if="showAdmision">
          <v-autocomplete v-model="form_qr.admision_id" :rules="[rules.requerido]" :items="items.admisiones"
            :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario"
            label="ADMISIÓN *"></v-autocomplete>
        </v-col>
          <v-col cols="12" md="6" class="pr-1" v-if="showEnteControl">
            <v-autocomplete v-model="form_qr.entecontrol_id" :rules="[rules.requerido]" :items="items.entecontroles"
              :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario"
              label="ENTE DE CONTROL *"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="marcacionID(registro.gesduplicado) < 3">
          <v-col cols="12" md="6" class="pr-1" >
            <v-autocomplete v-model="form_duplicado.duplicado_id"
              v-on:input="input_duplicado(form_duplicado.duplicado_id)" :rules="[rules.requerido]"
              :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id"
              :color="colores.primario" label="DUPLICADO"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" v-if="form_duplicado.duplicado_id===29" class="pr-1">
            <v-autocomplete v-model="form_duplicado.duplicadomotivo_id" :rules="[rules.requerido]"
              :items="items.motivoduplicados" :loading="loadingData" item-text="descripcion" item-value="id"
              :color="colores.primario" label="MOTIVO DUPLICADO"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" v-if="form_duplicado.duplicado_id===29">
            <v-text-field v-model="form_duplicado.codigo" type="number" min="0" autocomplete="off"
              :rules="[rules.requerido]" label="CÓDIGO DUPLICADO" :color="colores.primario"></v-text-field>
          </v-col>
          <v-col v-if="form_duplicado.duplicado_id!==29 && marcacionID(registro.gesduplicado) < 3">
            <v-switch v-model="switch1" color="success" label="Falta de competencia"
              @change="seleccionMarcacion('switch')"></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_duplicado.reincidente_id"  :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="REINCIDENTE"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6"  v-if="showCanalOri">
            <v-autocomplete v-model="form_duplicado.canaoriginol_id" :rules="[rules.requerido]" :items="items.canales" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="CANAL QUE ORIGINÓ QR"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="items.showMarcacion">
          <v-col cols="12" align-self="center">
            <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '16px' }]"><b>MARCACIÓN</b></span></v-toolbar>
            <v-radio-group v-model="form_duplicado.marcacion_id" :rules="[rules.requerido]" hide-details dense>
              <div v-for="(item, index) in items_marcacion" :key="index">
              <v-radio :color="colores.primario" :label="item.descripcion" :value="item.id"></v-radio>
              <v-divider></v-divider>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <br v-if="items.showMarcacion">
        <!--
        <v-row no-gutters>
          <v-col cols="12">
            <v-toolbar dense><b>{{ registro.motivo.descripcion }}</b></v-toolbar>
          </v-col>
        </v-row>
        -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete v-model="items.objMotivoQR" v-on:input="input_motivo(items.objMotivoQR)" return-object :rules="[rules.requerido]" :items="items.motivoprincipales" :loading="loadingMotivo" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO PRINCIPAL *"></v-autocomplete>
          </v-col>
        </v-row>
        <!--
        <v-row no-gutters>
          <v-col cols="12">
            <v-toolbar dense><b>{{ registro.submotivo.descripcion }}</b></v-toolbar>
          </v-col>
        </v-row>
        -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete v-model="items.objSubMotivoQR" v-on:input="input_submotivo(items.objSubMotivoQR)" :rules="[rules.requerido]" return-object :items="items_motivosqr" :loading="loadingMotivoQR" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="descripcionqr">
          <v-col cols="12">
            <span :style="[{'color': colores.primario}, {'font-size': 10 }]">DESCRIPCIÓN MOTIVO</span>
            <v-card-text style="white-space:pre-wrap;" class="pl-0 pt-0">
              <span>{{ descripcionqr }}</span>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="form_qr.tipo_id === 76 || form_qr.tipo_id === 77">
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_qr.quejaexpres_id" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="QUEJA EXPRÉS *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea v-model="form_qr.descripcion" readonly :rules="[rules.requerido]" :color="colores.primario" maxlength="4500" counter="4500" label="DESCRIPCIÓN DE LA QR (ESCRIBIR EL MOTIVO Y DETALLE DE LA QR) *" rows="6"></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro">
          <v-col cols="12">
            <v-list dense>
                <div v-for="(item, index) in registro.gesarchivos" :key="index">
                    <verArchivos :colores="colores" :tipo="'GESTION'" :item="item"></verArchivos>
                    <v-divider></v-divider>
                </div>
            </v-list>
          </v-col>
        </v-row>
        <br>
        <div v-if="dataReplica.length > 0">
          <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>ARGUMENTO RÉPLICA</b></span></v-toolbar>
          <v-row no-gutters v-for="(itemReplica, indexEsc) in dataReplica" :key="indexEsc">
            <v-col cols="12" md="" class="pr-1">
              <span :style="[{'color': colores.primario}, {'font-size': 10 }]">{{itemReplica.created_at.substr(0,
                10)}}</span>
              <v-card-text style="white-space:pre-wrap;" class="pl-0 pt-0">
                <span>{{ itemReplica.argumento_replica }}</span>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-sheet>

  <br>
  <v-dialog v-model="borrarMarcacion" persistent max-width="450">
    <v-card>
      <v-toolbar class="headline" :color="colores.primario" dark>MARCACIÓN</v-toolbar>
      <v-card-text>
        <br>
        ¿DESEA ELIMINAR LA MARCACIÓN?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" dark @click="eliminar(registro)">ACEPTAR</v-btn>
        <v-btn color="grey darken-1" dark @click="borrarMarcacion = false">CANCELAR</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="esperar" persistent max-width="450">
    <v-card :color="colores.primario">
        <v-card-text class="pt-4">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import recListado from '@/js/rec_listado.js'
import recSfc from '@/js/rec_sfc.js'
// import verArchivos from '@/components/widgets/ver_archivosComponent.vue'
export default {
  name: 'qrComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    texto3: () => import('@/components/widgets/text3Component.vue'),
    verArchivos: () => import('@/components/widgets/ver_archivosComponent.vue')
  },
  mixins: [recListado, recSfc],
  props: ['colores', 'tokenInbound', 'registro', 'session', 'items', 'loadingData', 'form_qr', 'form_duplicado', 'rules', 'form_files', 'dataReplica'],
  data: () => ({
    loadingMotivo: false,
    loadingMotivoQR: false,
    items_motivosqr: [],
    switch1: false,
    items_marcacion: [],
    descripcionqr: '',
    showCanal: true,
    showCanalOri: false,
    opcion: 0,
    showAdmision: false,
    showEnteControl: false,
    menu: false,
    borrarMarcacion: false,
    esperar: false
  }),
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        this.form_qr.admision_id = 107
        // this.rec_listado_motivo(this.form_qr.tipo_id)
      }
    },
    items: {
      immediate: true,
      handler (newVal, oldVal) {
        this.items.marcaciones.forEach(element => {
          if (element.descripcion === 'Si La Queja O Reclamo Fue Cerrada Por Falta De Competencia' || element.id === 485) {
            this.items_marcacion.push(element)
          }
        })
      }
    }
  },
  created () {
    // ASIGNAR QUEJA O RECLAMO
    this.items.objTipoQR = this.items.tiporequerimientos[1]
    this.input_tipo_inicial(this.items.objTipoQR, ((this.registro.motivo !== null) ? this.registro.motivo.id : 0))
  },
  methods: {
    async input_tipo_inicial (pObj, motivoId) {
      this.form_qr.motivo_id = 0
      this.form_qr.submotivo_id = 0
      this.items.objMotivoQR = null
      this.items.objSubMotivoQR = null
      this.form_qr.area = null
      this.form_qr.tipo_id = pObj.id
      this.showCanalOri = false

      if (pObj.id === 76 || pObj.id === 77) {
        this.showCanalOri = true
      }
      await this.rec_listado_motivo_inicial_micrositio(pObj.secundario_id, motivoId)
      this.input_motivo_inicial(this.items.objMotivoQR, ((this.registro.submotivo !== null) ? this.registro.submotivo.id : 0))
    },
    async input_motivo_inicial (pMotivo, submotivoId) {
      const evento = (pMotivo.evento !== null) ? pMotivo.evento.area_id : 0
      this.form_qr.area = (pMotivo === null) ? null : evento
      this.form_qr.motivo_id = (pMotivo === null) ? null : pMotivo.id
      this.form_qr.submotivo_id = 0
      this.items.objSubMotivoQR = null
      this.descripcionqr = ''
      if (pMotivo !== null) {
        await this.rec_listado_submotivo_inicial_micrositio(pMotivo.id, submotivoId)
        this.input_submotivo(this.items.objSubMotivoQR)
      }
    },
    input_tipo (pObj) {
      this.form_qr.motivo_id = 0
      this.form_qr.submotivo_id = 0
      this.items.objMotivoQR = null
      this.items.objSubMotivoQR = null
      this.form_qr.area = null
      this.form_qr.tipo_id = pObj.id
      this.form_qr.quejaexpres_id = null
      this.showCanalOri = false

      if (pObj.id === 76 || pObj.id === 77) {
        this.showCanalOri = true
      }
      this.rec_listado_motivo(pObj.secundario_id)
    },
    input_duplicado (pDuplicadoId) {
      this.items.showMarcacion = false
      this.form_duplicado.duplicadomotivo_id = null
      this.form_duplicado.codigo = 0
      if (pDuplicadoId === 29) {
        this.items.showMarcacion = true
        this.switch1 = false
        this.seleccionMarcacion('duplicado')
      } else {
        this.form_duplicado.marcacion_id = null
      }
    },
    input_motivo (pMotivo) {
      this.form_qr.area = (pMotivo.evento === null) ? null : pMotivo.evento.area_id
      this.form_qr.motivo_id = pMotivo.id
      this.form_qr.submotivo_id = 0
      this.items.objSubMotivoQR = null
      this.descripcionqr = ''
      this.rec_listado_submotivo(pMotivo.id)
    },
    input_submotivo (pObj) {
      this.form_qr.submotivo_id = pObj.id
      this.form_qr.diasext = (pObj.accion === null) ? 0 : pObj.accion.diasext
      this.form_qr.diasint = (pObj.accion === null) ? 0 : pObj.accion.diasint
      this.descripcionqr = pObj.opcion1
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.form_files.items_archivos.push(data[i])
      }
    },
    seleccionMarcacion (llamado) {
      this.switch1 ? this.opcion = 1 : this.opcion = 2
      this.items_marcacion = []
      this.form_duplicado.marcacion_id = null
      if (this.opcion === 1) {
        this.items.marcaciones.forEach(element => {
          if (element.descripcion === 'Si La Queja O Reclamo Fue Cerrada Por Falta De Competencia' || element.id === 485) {
            this.items_marcacion.push(element)
          }
        })
        this.items.showMarcacion = true
      } else if (this.opcion === 2) {
        this.items.marcaciones.forEach(element => {
          if (element.descripcion === 'Si La Queja Fue Cerrada Por Estar Repetida En Smartsupervision' || element.id === 488) {
            this.items_marcacion.push(element)
          }
        })
        this.items.showMarcacion = true
      }
      if (llamado === 'switch' && this.opcion === 2) {
        this.items.showMarcacion = false
      }
    },
    marcacionID (gesduplicado) {
      let retorno = 0
      if (gesduplicado !== null) {
        if (gesduplicado.marcacion_id !== 488) {
          retorno = 3
        }
        if (gesduplicado.marcacion_id === null) {
          retorno = 2
        }
      } else {
        retorno = 1
      }
      return retorno
    },
    eliminar (registro) {
      this.esperar = true
      this.eliminarMarcacionJS(registro.id)
    }
  }
}
</script>
